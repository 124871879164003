import { Button, Dropdown, Input, MenuProps, Space } from "antd";
import { SearchProps } from "antd/es/input";
import { useEffect, useState } from "react";
import styled from "styled-components";
import FolderCard from "./folderCard";
import FolderList from "./foldersList";
import ModulesList from "./modulesList";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { connect, useDispatch, useSelector } from "react-redux";
import { getAllFiders, getAllModules } from "ce/actions/modulesActions";
import AddFolderModal from "./components/addFolder";
import AddModuleModal from "./components/addModule";
import { AppState } from "@appsmith/reducers";
import { getFoldersState, getModulesState } from "ce/selectors/moduleSelectors";
import { ReduxActionErrorTypes1 } from "ce/constants/ReduxActionConstants";

const StyledApplicationModulesContainer = styled.div`
  margin-top: 48px;
  padding: 40px 25px;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const StyledSubTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const { Search } = Input;

const items: MenuProps["items"] = [
  {
    label: "文件夹",
    key: "1",
  },
  {
    label: "模块",
    key: "2",
  },
];
const ApplicationModules: React.FC<any> = (props) => {
  const [isFolderModalOpen, setFolderModalOpen] = useState<boolean>(false);
  const [isModuleModalOpen, setModuleModalOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  // const { getAllFiders, foldersData } = props;
  const dispatch = useDispatch();
  const foldersData = useSelector(getFoldersState);
  const modulesData = useSelector(getModulesState);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "1") {
      setFolderModalOpen(true);
    } else {
      setModuleModalOpen(true);
    }
  };

  const closeFolderModal = () => {
    setFolderModalOpen(false);
    dispatch({
      type: ReduxActionErrorTypes1.ADD_FOLDER_ERROR,
      payload: { error: { message: "" } },
    });
  };

  const colseModuleModal = () => {
    dispatch({
      type: ReduxActionErrorTypes1.ADD_MODULE_ERROR,
      payload: { error: { message: "" } },
    });
    setModuleModalOpen(false);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setName(value);
  };

  useEffect(() => {
    dispatch(getAllFiders(name));
    dispatch(getAllModules(name));
  }, [name]);

  return (
    <StyledApplicationModulesContainer>
      <StyledHeader>
        <StyledTitle>我的空间</StyledTitle>
        <div>
          <Search
            placeholder="请输入"
            onSearch={onSearch}
            style={{ width: 200, marginRight: "20px" }}
          />
          <Dropdown menu={menuProps}>
            <Button type="primary">
              <Space>
                新建
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </StyledHeader>
      <StyledSubTitle>全部文件夹</StyledSubTitle>
      <FolderList foldersData={foldersData} />
      <StyledSubTitle>模块</StyledSubTitle>
      <ModulesList modulesData={modulesData} />
      {isFolderModalOpen && (
        <AddFolderModal
          openModal={isFolderModalOpen}
          closeModal={closeFolderModal}
        ></AddFolderModal>
      )}
      {isModuleModalOpen && (
        <AddModuleModal
          openModal={isModuleModalOpen}
          closeModal={colseModuleModal}
        ></AddModuleModal>
      )}
    </StyledApplicationModulesContainer>
  );
};

// const mapStateToProps = (state: AppState) => ({
//   foldersData: getFoldersState(state),
// });

// // const mapDispatchToProps = (dispatch: any) => ({
// //   getAllFiders: () => dispatch(getAllFiders()),
// // });

// const mapDispatchToProps = (dispatch: any) => {
//   console.log("dispatch", dispatch);
//   return {
//     getAllFiders: () => dispatch(getAllFiders()),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(ApplicationModules);
export default ApplicationModules;
