import _ from "lodash";

type iconObj = {
  iconName: string;
  type: string;
  category: string;
};

const isJsonString = (str: string | iconObj): boolean => {
  if (!_.isString(str)) {
    return false;
  }
  if (
    (_.startsWith(str, "{") && _.endsWith(str, "}")) ||
    (_.startsWith(str, "[") && _.endsWith(str, "]"))
  ) {
    try {
      const parsed = JSON.parse(str);
      return _.isObject(parsed) && !_.isNull(parsed);
    } catch (e) {
      return false;
    }
  }

  return false;
};

export function getIconParams(icon): iconObj {
  const iconObj =
    icon === "" || icon === undefined
      ? { iconName: "", type: "antd", category: "direction" }
      : isJsonString(icon)
        ? JSON.parse(icon)
        : _.isObject(icon)
          ? icon
          : { iconName: icon, type: "appsmith", category: "appsmith" };
  return iconObj;
}
