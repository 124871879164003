import { AppState } from "ce/reducers";
import { isEmpty } from "lodash";

export const getFoldersState = (state: AppState) => {
  //  当搜索时，检索文件夹下的模块
  if (!isEmpty(state.modules.modules.modulesData)) {
    // 过滤包含 folderId 的数据
    const modulesWithFolderId = state.modules.modules.modulesData
      .filter((item) => item.folderId)
      .map((it) => {
        return {
          id: it.folderId,
          name: it.folderName,
        };
      });

    // 合并到 foldersData 中
    const updatedFoldersData =
      state.modules.modules.foldersData.concat(modulesWithFolderId);
    // 更新 state
    state.modules.modules.foldersData = updatedFoldersData;
    return state.modules.modules.foldersData;
  }
  return state.modules.modules.foldersData;
};

export const getModulesState = (state: AppState) => {
  const modulesWithFolderId = state.modules.modules.modulesData.filter(
    (item) => !item.folderId,
  );
  state.modules.modules.modulesData = modulesWithFolderId;
  return state.modules.modules.modulesData;
};

export const getFolderModulesState = (state: AppState) => {
  return state.modules.modules.folderModulesData;
};
