// import localStorage from "utils/localStorage";
import { GridDefaults } from "./WidgetConstants";

export const CANVAS_DEFAULT_HEIGHT_PX = 1292;
export const CANVAS_DEFAULT_MIN_HEIGHT_PX = 480;
export const CANVAS_DEFAULT_GRID_HEIGHT_PX = 1;
export const CANVAS_DEFAULT_GRID_WIDTH_PX = 1;
export const CANVAS_DEFAULT_MIN_ROWS = Math.ceil(
  CANVAS_DEFAULT_MIN_HEIGHT_PX / GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
);
export const CANVAS_BACKGROUND_COLOR = "#FFFFFF";
export const DEFAULT_ENTITY_EXPLORER_WIDTH = 256;
export const DEFAULT_PROPERTY_PANE_WIDTH = 288;
export const APP_SETTINGS_PANE_WIDTH = 525;
export const DEFAULT_EDITOR_PANE_WIDTH = 255;
export const DEFAULT_HISTORY_EDITOR_PANE_WIDTH = 300;
export const DEFAULT_SPLIT_SCREEN_WIDTH = "40.4vw";

export const DEFAULT_VIEWER_LOGO = "/logo/pageplug_icon.svg";

const APP_STORE_NAMESPACE = "APPSMITH_LOCAL_STORE";

export const getAppStoreName = (appId: string, branch?: string) =>
  branch
    ? `${APP_STORE_NAMESPACE}-${appId}-${branch}`
    : `${APP_STORE_NAMESPACE}-${appId}`;

export const getPersistentAppStore = (appId: string, branch?: string) => {
  const appStoreName = getAppStoreName(appId, branch);
  let storeString = "{}";
  // Check if localStorage exists
  if (localStorage.getItem) {
    const appStore = localStorage.getItem(appStoreName);
    if (appStore) storeString = appStore;
  }
  let store;
  try {
    store = JSON.parse(storeString);
  } catch (e) {
    store = {};
  }
  return store;
};

export const TOOLTIP_HOVER_ON_DELAY = 1000;
export const TOOLTIP_HOVER_ON_DELAY_IN_S = 1;

export const MOBILE_MAX_WIDTH = 767;
export const TABLET_MIN_WIDTH = 768;
export const TABLET_MAX_WIDTH = 991;
export const DESKTOP_MIN_WIDTH = 992;

export const NAVIGATION_SETTINGS = {
  ORIENTATION: {
    TOP: "top",
    SIDE: "side",
  },
  NAV_STYLE: {
    STACKED: "stacked",
    INLINE: "inline",
    SIDEBAR: "sidebar",
    MINIMAL: "minimal",
  },
  POSITION: {
    STATIC: "static",
    STICKY: "sticky",
  },
  NAV_POSITION: {
    LEFT: "left",
    CENTER: "center",
  },
  ITEM_STYLE: {
    TEXT_ICON: "textIcon",
    TEXT: "text",
    ICON: "icon",
  },
  COLOR_STYLE: {
    LIGHT: "light",
    THEME: "theme",
  },
  LOGO_CONFIGURATION: {
    LOGO_AND_APPLICATION_TITLE: "logoAndApplicationTitle",
    LOGO_ONLY: "logoOnly",
    APPLICATION_TITLE_ONLY: "applicationTitleOnly",
    NO_LOGO_OR_APPLICATION_TITLE: "noLogoOrApplicationTitle",
  },
};

export interface NavigationSetting {
  showNavbar: boolean;
  showSignIn: boolean;
  showBreadcrumb: boolean;
  orientation: (typeof NAVIGATION_SETTINGS.ORIENTATION)[keyof typeof NAVIGATION_SETTINGS.ORIENTATION];
  navStyle: (typeof NAVIGATION_SETTINGS.NAV_STYLE)[keyof typeof NAVIGATION_SETTINGS.NAV_STYLE];
  position: (typeof NAVIGATION_SETTINGS.POSITION)[keyof typeof NAVIGATION_SETTINGS.POSITION];
  itemStyle: (typeof NAVIGATION_SETTINGS.ITEM_STYLE)[keyof typeof NAVIGATION_SETTINGS.ITEM_STYLE];
  colorStyle: (typeof NAVIGATION_SETTINGS.COLOR_STYLE)[keyof typeof NAVIGATION_SETTINGS.COLOR_STYLE];
  logoAssetId: string;
  logoConfiguration: (typeof NAVIGATION_SETTINGS.LOGO_CONFIGURATION)[keyof typeof NAVIGATION_SETTINGS.LOGO_CONFIGURATION];
}

export interface ThemeSetting {
  accentColor: string;
  colorMode: "LIGHT" | "DARK";
  borderRadius: string;
  density: number;
  sizing: number;
  fontFamily: string;
  iconStyle: "FILLED" | "OUTLINED";
}

export type StringsFromNavigationSetting = Omit<
  NavigationSetting,
  "showNavbar" | "showSignIn" | "showBreadcrumb"
>;

export const keysOfNavigationSetting = {
  showNavbar: "showNavbar",
  showSignIn: "showSignIn",
  showBreadcrumb: "showBreadcrumb",
  orientation: "orientation",
  navStyle: "navStyle",
  position: "position",
  itemStyle: "itemStyle",
  colorStyle: "colorStyle",
  logoAssetId: "logoAssetId",
  logoConfiguration: "logoConfiguration",
};

export const defaultNavigationSetting = {
  showNavbar: true,
  showSignIn: true,
  orientation: NAVIGATION_SETTINGS.ORIENTATION.TOP,
  navStyle: NAVIGATION_SETTINGS.NAV_STYLE.STACKED,
  position: NAVIGATION_SETTINGS.POSITION.STATIC,
  itemStyle: NAVIGATION_SETTINGS.ITEM_STYLE.TEXT,
  colorStyle: NAVIGATION_SETTINGS.COLOR_STYLE.LIGHT,
  logoConfiguration:
    NAVIGATION_SETTINGS.LOGO_CONFIGURATION.LOGO_AND_APPLICATION_TITLE,
};

export const defaultThemeSetting: ThemeSetting = {
  fontFamily: "System Default",
  accentColor: "#0080ff",
  colorMode: "LIGHT",
  borderRadius: "6px",
  density: 1,
  sizing: 1,
  iconStyle: "OUTLINED",
};

export const SIDEBAR_WIDTH = {
  REGULAR: 270,
  NEWREGULAR: 220,
  INLINEREGULAR: 206,
  MINIMAL: 64,
};

export const APP_SIDEBAR_WIDTH = 50;

export const APPLICATION_TITLE_MAX_WIDTH = 165;
export const APPLICATION_TITLE_MAX_WIDTH_MOBILE = 150;
//all values are in milliseconds
export const REQUEST_IDLE_CALLBACK_TIMEOUT = {
  highPriority: 1500,
  lowPriority: 3000,
};

type ExtraActionType =
  | "layout"
  | "delete"
  | "add"
  | "modify"
  | "rename"
  | "recover"
  | "upgrade"
  | "publish";

export enum OperationType {
  layout = "layout",
  delete = "delete",
  add = "add",
  modify = "modify",
  rename = "rename",
  recover = "recover",
  upgrade = "upgrade",
  publish = "publish",
}

export enum OperationLabel {
  layout = "布局",
  delete = "删除",
  add = "新增",
  modify = "修改",
  rename = "重命名",
  recover = "回退",
  upgrade = "升级",
  publish = "发布",
}

export type AppSnapshotContext = {
  operations: Operation[];
};

export interface Operation {
  compType?: string;
  compName: string | any;
  oldName?: string;
  operation: ExtraActionType;
  snapshotCreateTime?: number | string;
}

export type AppSnapshot = {
  snapshotId: string;
  context: AppSnapshotContext;
  username: string;
  userAvatar?: string;
  createTime: string;
  data: any;
};

export type AppSnapshotList = {
  count: number; // total count
  list: AppSnapshot[];
};
