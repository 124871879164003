export * from "ce/api/TenantApi";
import Api from "api/Api";
import type { AxiosPromise, AxiosResponse } from "axios";
import type { FetchCurrentTenantConfigResponse } from "ce/api/TenantApi";
import { TenantApi as CE_TenantApi } from "ce/api/TenantApi";

export interface UpdateCurrentTenantConfigRequest {
  logo?: File;
  favicon?: File;
  brandColors?: {
    background: string;
    disabled: string;
    font: string;
    hover: string;
    primary: string;
  };
}

class TenantApi extends CE_TenantApi {
  static async updateCurrentTenantConfig(
    request: UpdateCurrentTenantConfigRequest,
  ): Promise<AxiosResponse<FetchCurrentTenantConfigResponse, any>> {
    const formData = new FormData();
    if (request.logo) {
      formData.append("logo", request.logo);
    }
    if (request.favicon) {
      formData.append("favicon", request.favicon);
    }
    if (request.brandColors) {
      formData.append(
        "brandColors",
        new Blob([JSON.stringify(request.brandColors)], {
          type: "application/json",
        }),
      );
    }

    return Api.post(TenantApi.tenantsUrl + "/branding", formData, null, {
      headers: {
        "Content-Type": "",
      },
    });
  }
}

export default TenantApi;
