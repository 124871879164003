import React from "react";

const FolderSvg = ({ width = "24px", height = "24px", style = {} }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: "10px", flexShrink: 0, ...style }}
  >
    <defs>
      <linearGradient
        x1="50%"
        y1="4.38271658%"
        x2="50%"
        y2="100%"
        id="icon-application-folder_svg__a"
      >
        <stop stopColor="#FFD640" offset="0%"></stop>
        <stop stopColor="#FFB60D" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(2.000000, 2.500000)">
        <path
          d="M1.51834581,0 L8.21208124,0 C8.75296006,-1.28219623e-15 9.25195183,0.29119106 9.51802909,0.762097418 L10.5,2.5 L10.5,2.5 L14.5,2.5 C15.3284271,2.5 16,3.17157288 16,4 L16,4.89285714 C16,5.72128427 15.3284271,6.39285714 14.5,6.39285714 L1.50563157,6.39285714 C0.677204443,6.39285714 0.00563156739,5.72128427 0.00563156739,4.89285714 C0.00563156739,4.89098347 0.00563507803,4.8891098 0.00564209929,4.88723615 L0.0183563461,1.494379 C0.0214525137,0.66815197 0.692112979,5.95865719e-16 1.51834581,0 Z"
          fill="#FFAE0D"
        ></path>
        <path
          d="M1.5,2.5 L14.5,2.5 C15.3284271,2.5 16,3.17157288 16,4 L16,13 C16,13.8284271 15.3284271,14.5 14.5,14.5 L1.5,14.5 C0.671572875,14.5 1.01453063e-16,13.8284271 0,13 L0,4 C-1.01453063e-16,3.17157288 0.671572875,2.5 1.5,2.5 Z"
          fill="url(#icon-application-folder_svg__a)"
        ></path>
      </g>
    </g>
  </svg>
);

export default FolderSvg;
