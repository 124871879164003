import {
  Button,
  Card,
  Dropdown,
  Form,
  Input,
  MenuProps,
  message,
  Modal,
  Progress,
  Space,
  Upload,
  UploadProps,
} from "antd";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { InboxOutlined, FileTextOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { addModule, importApplication } from "ce/actions/modulesActions";
import { useParams } from "react-router";
import { ReduxActionErrorTypes1 } from "ce/constants/ReduxActionConstants";
import { FilePickerV2, FileType, SetProgress } from "design-system-old";
import { createMessage } from "design-system-old/build/constants/messages";
import {
  IMPORT_APP_FROM_FILE_MESSAGE,
  IMPORT_APP_FROM_FILE_TITLE,
  UPLOADING_APPLICATION,
} from "ce/constants/messages";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";

const CardStyles = css`
  height: 200px;
  border: 1px solid var(--ads-v2-color-border);
  border-radius: var(--ads-v2-border-radius);
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  &:hover {
    background: var(--ads-v2-color-bg-subtle);
  }
`;

const FileImportCard = styled.div<{ fillCardWidth: boolean }>`
  ${CardStyles}
  width: ${(props) => (props.fillCardWidth ? "100%" : "320px")};
  .ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon {
    border-radius: 50%;
    width: ${(props) => props.theme.spaces[12] + 2}px;
    height: ${(props) => props.theme.spaces[12] + 2}px;
    background: var(--ads-v2-color-bg-muted);
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    svg {
      width: ${(props) => props.theme.iconSizes.XL}px;
      height: ${(props) => props.theme.iconSizes.XL}px;

      path {
        color: var(--ads-v2-color-fg) !important;
      }
    }
  }
`;

const StyledFormWrapper = styled.div`
  margin-top: 25px;
  // display: flex;
  // align-items: center;
  .ant-card .ant-card-body {
    &:hover {
      background: red;
    }
  }

  .ant-upload-wrapper .ant-upload-drag {
    border: none;
    background: transparent none;
  }
  .ant-upload-wrapper {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
`;

const { Meta } = Card;
const { Dragger } = Upload;

const AddModuleModal: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isAddNewModule, setIsAddNewModule] = useState<boolean>(false);
  const [isAddUploadModule, setIsAddUploadModule] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [appFileToBeUploaded, setAppFileToBeUploaded] = useState<{
    file: RcFile;
  } | null>(null);
  const dispatch = useDispatch();
  const params = useParams() as any;

  const { errorAddModuleMsg, canCloseModuleModal } = useSelector(
    (state: any) => state.modules.modules,
  );

  useEffect(() => {
    if (errorAddModuleMsg) {
      form.setFields([
        {
          name: "name",
          errors: [errorAddModuleMsg],
        },
      ]);
    }
  }, [errorAddModuleMsg, form]);

  useEffect(() => {
    if (canCloseModuleModal) {
      handleCancel();
    }
  }, [canCloseModuleModal]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        dispatch(addModule(values.name, params.folderId || ""));
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    props.closeModal();
  };

  const onValuesChange = (changedValues, allValues) => {
    if (errorAddModuleMsg) {
      clearErrorMsg();
    }
    setIsDisabled(isEmpty(allValues.name));
  };

  const onAddModule = () => {
    setIsAddNewModule(true);
  };

  useEffect(() => {
    // 清空错误
    clearErrorMsg();
  }, []);

  const clearErrorMsg = () => {
    dispatch({
      type: ReduxActionErrorTypes1.ADD_MODULE_ERROR,
      payload: { error: { message: "" } },
    });
  };

  const FileUploader = useCallback(async (file: UploadChangeParam) => {
    if (!!file) {
      dispatch(
        importApplication({
          folderId: params.folderId || ("" as string),
          moduleFile: file.file,
          onSuccessCallback: () => handleCancel(),
        }),
      );
    } else {
      setAppFileToBeUploaded(null);
    }
  }, []);

  const onRemoveFile = useCallback(() => setAppFileToBeUploaded(null), []);

  const uploadProps: UploadProps = {
    name: "file",
    showUploadList: false,
    onChange: FileUploader,
    beforeUpload: () => {
      // 阻止自动上传
      return false;
    },
    // onDrop: handleChange,
  };

  return (
    <div>
      <Modal
        title="新建模块"
        open={props.openModal}
        footer={
          isAddNewModule
            ? [
                <Button key="cancel" onClick={handleCancel}>
                  取消
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  disabled={isDisabled}
                  onClick={handleOk}
                >
                  创建
                </Button>,
              ]
            : null
        }
        onCancel={props.closeModal}
      >
        <StyledFormWrapper>
          {isAddNewModule ? (
            <div>
              <Form
                name="basic"
                layout="vertical"
                form={form}
                labelCol={{ span: 6 }}
                autoComplete="off"
                onValuesChange={onValuesChange}
              >
                <Form.Item
                  label="模块名称"
                  name="name"
                  rules={[
                    { required: true, message: "请输入模块名字" },
                    {
                      validator: async (_, value) => {
                        if (errorAddModuleMsg && value) {
                          throw new Error(errorAddModuleMsg);
                        }
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div className="flex items-center">
              <FileImportCard
                className="t--import-json-card mr-[20px]"
                fillCardWidth={true}
                onClick={onAddModule}
              >
                <div className="p-4 flex items-center flex-col">
                  <div className="icon">
                    <FileTextOutlined />
                  </div>
                  <span className="text-[#4c5664]">新建一个全新的模块</span>
                </div>
              </FileImportCard>
              <FileImportCard
                className="t--import-json-card"
                fillCardWidth={true}
              >
                <Dragger {...uploadProps}>
                  <div className="icon">
                    <FileTextOutlined />
                  </div>
                  <span className="text-[#4c5664]">导入已有模块</span>
                  <span className="text-[#4c5664]">
                    将文件拖拽到这里，或点击上传
                  </span>
                </Dragger>
              </FileImportCard>
            </div>
          )}
        </StyledFormWrapper>
      </Modal>
    </div>
  );
};

export default AddModuleModal;
