import {
  Button,
  Card,
  Dropdown,
  MenuProps,
  Modal,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import FolderSvg from "./folderSvg";
import { useHistory } from "react-router-dom";
import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { deleteFolder, updateFolderName } from "ce/actions/modulesActions";

const Wrapper = styled.div`
  // height: 52px;
  //   padding: 0 6px;
  border-radius: 8px;
  //   margin-bottom: -1px;
  //   margin-top: 1px;

  &:hover {
    border-color: transparent;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  .ant-card .ant-card-body {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 20px 24px;
  }
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
  white-space: nowrap;
  width: 284px;
  height: 100%;
  flex-grow: 1;
  cursor: pointer;
  overflow: hidden;
  padding-right: 12px;

  .ant-typography-edit-content {
    inset-inline-start: 0px !important;
    margin-top: 0px !important;
    margin-bottom: calc(1em - 18px) !important;
  }
`;

const StyledDropDownWrapper = styled.div`
  width: 25px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    background-color: #e1e3eb;
    border-radius: 4px;
  }
`;

const items: MenuProps["items"] = [
  {
    label: "重命名",
    key: "1",
  },
  {
    label: "删除",
    key: "2",
    danger: true,
  },
];

const { Paragraph, Text } = Typography;

const FolderCard: React.FC<any> = (props) => {
  const { cardInfo } = props;
  const [appNameEditing, setAppNameEditing] = useState<boolean>(false);
  const [editableStr, setEditableStr] = useState("This is an editable text.");
  const history = useHistory();
  const dispatch = useDispatch();

  const error = () => {
    Modal.error({
      title: "确认删除吗？",
      content: "删除后该文件夹将无法找回",
      footer: (
        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button key="back" onClick={() => Modal.destroyAll()}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              dispatch(deleteFolder(cardInfo.id));
              Modal.destroyAll();
            }}
            style={{ marginLeft: 8 }}
          >
            确定
          </Button>
        </div>
      ),
    });
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    e.domEvent.stopPropagation();
    setAppNameEditing(e.key === "1" ? true : false);
    if (e.key === "2") {
      error();
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const goToDetail = (id: string) => {
    if (!appNameEditing) {
      history.push(`/modules/${id}`);
    }
  };

  const handleEditStatusChange = (status) => {
    if (!status) {
      // 如果编辑状态结束，执行相关操作
      console.log("Edit ended");
    }
  };

  return (
    <Wrapper>
      <Card onClick={() => goToDetail(cardInfo.id)}>
        <FolderSvg />
        <CardInfo>
          <Text
            editable={{
              enterIcon: null,
              icon: null,
              tooltip: true,
              triggerType: ["text"],
              editing: appNameEditing,
              autoSize: { minRows: 1, maxRows: 1 },
              onChange: (e) => {
                setEditableStr(e);
                setAppNameEditing(false);
                dispatch(updateFolderName(e, cardInfo.id));
              },
            }}
          >
            <span
              title={cardInfo.name}
              className="truncate max-w-[160px] block"
            >
              {cardInfo.name}
            </span>
          </Text>
        </CardInfo>
        <StyledDropDownWrapper onClick={(e) => e.stopPropagation()}>
          <Dropdown menu={menuProps}>
            <Space>
              <EllipsisOutlined />
            </Space>
          </Dropdown>
        </StyledDropDownWrapper>
      </Card>
    </Wrapper>
  );
};

export default FolderCard;
