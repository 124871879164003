import type { TPrintDescription } from "workers/Evaluation/fns/printFns";

export default function printSaga(action?: TPrintDescription) {
  // if (typeof payload.data !== "string") {
  //   throw new ActionValidationError(
  //     "PRINT",
  //     "data",
  //     Types.STRING,
  //     getType(payload.data),
  //   );
  // }
  window.print();
}
