export * from "ce/sagas/modulesSaga";

import {
  getFoldersSaga,
  addFoldersSaga,
  deleteFoldersSaga,
  updateFoldersSaga,
  getModulesSaga,
  addModuleSaga,
  deleteModuleSaga,
  copyModuleSaga,
  moveModuleSaga,
  getFolderModulesSaga,
  updateModuleSaga,
  importApplicationSaga,
} from "ce/sagas/modulesSaga";

import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import { takeLatest, all } from "redux-saga/effects";

export default function* modulesSagas() {
  yield all([
    takeLatest(ReduxActionTypes.INIT_MODULES_FOLDERS, getFoldersSaga),
    takeLatest(ReduxActionTypes.ADD_FOLDER, addFoldersSaga),
    takeLatest(ReduxActionTypes.DELETE_FOLDER, deleteFoldersSaga),
    takeLatest(ReduxActionTypes.UPDATE_FOLDER_NAME, updateFoldersSaga),

    takeLatest(ReduxActionTypes.INIT_MODULES, getModulesSaga),
    takeLatest(ReduxActionTypes.ADD_MODULE, addModuleSaga),
    takeLatest(ReduxActionTypes.DELETE_MODULE, deleteModuleSaga),
    takeLatest(ReduxActionTypes.COPY_MODULE, copyModuleSaga),
    takeLatest(ReduxActionTypes.MOVE_MODULE_TO_FOLDER, moveModuleSaga),
    takeLatest(ReduxActionTypes.MODULE_RENAME, updateModuleSaga),
    takeLatest(ReduxActionTypes.IMPORT_MODULE, importApplicationSaga),

    takeLatest(ReduxActionTypes.INIT_FOLDER_MODULES, getFolderModulesSaga),
  ]);
}
