import AppsmithConsole from "utils/AppsmithConsole";
import { ActionValidationError } from "sagas/ActionExecution/errorUtils";
import { getType, Types } from "utils/TypeHelpers";
import { select } from "redux-saga/effects";
import { isMobileLayout } from "selectors/applicationSelectors";
import Taro from "@tarojs/taro";
import type { ToastKind } from "design-system";
import type { TShowAlertDescription } from "workers/Evaluation/fns/showAlert";
import { call } from "redux-saga/effects";
import showToast from "sagas/ToastSagas";
import { uniqueId } from "lodash";

export default function* showAlertSaga(action: TShowAlertDescription) {
  const { payload } = action;
  const delay = Number(payload.delay);
  const delayToNumber = Math.min(delay === 0 ? 0.1 : delay, 100);
  if (typeof payload.message !== "string") {
    throw new ActionValidationError(
      "SHOW_ALERT",
      "message",
      Types.STRING,
      getType(payload.message),
    );
  }
  if (Number.isNaN(delayToNumber) && payload.delay !== undefined) {
    throw new ActionValidationError(
      "SHOW_ALERT",
      "delay",
      Types.NUMBER,
      getType(payload.delay),
    );
  }
  const isMobile: boolean = yield select(isMobileLayout);
  if (isMobile) {
    let iconStr: any = "none";
    if (payload.style === "success") {
      iconStr = "success";
    }
    if (payload.style === "loading") {
      iconStr = "loading";
    }
    Taro.showToast({
      icon: iconStr,
      title: payload.message,
    });
    return;
  }
  // This is the toast that is rendered which is user generated by using `showAlert` platform function. This is forceDisplayed no matter the conditions.
  yield call(
    showToast,
    payload.message,
    {
      kind: payload.style as ToastKind,
      toastId: uniqueId("ToastId"),
      autoClose:
        payload.delay === undefined || (payload.delay as any) === ""
          ? false
          : delayToNumber * 1000,
    },
    { forceDisplay: true },
  );
  AppsmithConsole.info({
    text: payload.style
      ? `showAlert('${payload.message}', '${payload.style}') was triggered`
      : `showAlert('${payload.message}') was triggered`,
  });
}
