import { Button, Dropdown, Form, Input, MenuProps, Modal, Space } from "antd";
import { addFolder } from "ce/actions/modulesActions";
import {
  ReduxActionErrorTypes,
  ReduxActionErrorTypes1,
} from "ce/constants/ReduxActionConstants";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledFormWrapper = styled.div`
  margin-top: 25px;
`;

const AddFolderModal: React.FC<any> = (props) => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const { errorAddFolderMsg, canCloseFolderModal } = useSelector(
    (state: any) => state.modules.modules,
  );

  useEffect(() => {
    if (errorAddFolderMsg) {
      form.setFields([
        {
          name: "name",
          errors: [errorAddFolderMsg],
        },
      ]);
    }
  }, [errorAddFolderMsg, form]);

  useEffect(() => {
    if (canCloseFolderModal) {
      handleCancel();
    }
  }, [canCloseFolderModal]);

  const dispatch = useDispatch();

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        return dispatch(addFolder(values.name));
      })
      .catch((errorInfo) => {
        console.log("Failed:", errorInfo);
      });
  };

  const handleCancel = () => {
    props.closeModal();
  };

  const onValuesChange = (changedValues, allValues) => {
    if (errorAddFolderMsg) {
      clearErrorMsg();
    }
    setIsDisabled(isEmpty(allValues.name));
  };

  useEffect(() => {
    // 清空错误
    clearErrorMsg();
  }, []);

  const clearErrorMsg = () => {
    dispatch({
      type: ReduxActionErrorTypes1.ADD_FOLDER_ERROR,
      payload: { error: { message: "" } },
    });
  };

  return (
    <div>
      <Modal
        title="新建文件夹"
        okText="创建"
        open={props.openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: isDisabled }}
      >
        <StyledFormWrapper>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            labelCol={{ span: 6 }}
            autoComplete="off"
            onValuesChange={onValuesChange}
          >
            <Form.Item
              label="文件夹名称"
              name="name"
              rules={[
                { required: true, message: "请输入名字" },
                {
                  validator: async (_, value) => {
                    if (errorAddFolderMsg && value) {
                      throw new Error(errorAddFolderMsg);
                    }
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </StyledFormWrapper>
      </Modal>
    </div>
  );
};

export default AddFolderModal;
