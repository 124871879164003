export * from "ce/reducers/tenantReducer";
import {
  handlers as handlersCE,
  initialState,
  defaultBrandingConfig,
  TenantReduxState,
} from "ce/reducers/tenantReducer";
import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "@appsmith/constants/ReduxActionConstants";
export const handlers = {
  ...handlersCE,
  [ReduxActionTypes.UPDATE_CURRENT_TENANT_CONFIG_SUCCESS]: (
    state: TenantReduxState,
    action: ReduxAction<TenantReduxState>,
  ) => ({
    ...state,
    userPermissions: action.payload.userPermissions || [],
    tenantConfiguration: {
      ...defaultBrandingConfig,
      ...action.payload.tenantConfiguration,
    },
  }),
  [ReduxActionErrorTypes.UPDATE_CURRENT_TENANT_CONFIG_ERROR]: (
    state: TenantReduxState,
  ) => ({
    ...state,
  }),
};

export default createReducer(initialState, handlers);
