import type { AxiosPromise, AxiosResponse } from "axios";
import Api from "api/Api";
import type { ApiResponse } from "api/ApiResponses";

export interface UpdateGroupUsersRequest {
  usernames: string[];
  groupIds: string[];
}

export interface UpdateUserGroupsRequest {
  usernames: string[];
  groupsAdded: string[];
  groupsRemoved: string[];
}

export interface UpdateGroupRequest {
  name?: string;
  description?: string;
}

export interface GroupData {
  id: string;
  name: string;
  description: string;
  users: string[];
}

export interface GroupDetailData {
  id: string;
  name: string;
  description: string;
  roles: {
    name: string;
    id: string;
  }[];
  users: {
    name: string;
    id: string;
  }[];
}

class UserGroupApi extends Api {
  static baseURL = "v1/user-groups";

  static async fetchUserGroupsForInvite(): Promise<
    AxiosResponse<ApiResponse<unknown>, any>
  > {
    return Api.get(`${UserGroupApi.baseURL}/for-invite`);
  }

  static async fetchAllUserGroups(): Promise<
    AxiosResponse<ApiResponse<unknown>, any>
  > {
    return Api.get(`${UserGroupApi.baseURL}`);
  }

  static async fetchUserGroupDetail(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.get(`${UserGroupApi.baseURL}/${id}`);
  }

  static async deleteUserGroup(
    id: string,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.delete(`${UserGroupApi.baseURL}/${id}`);
  }

  static async createUserGroup(
    request: UpdateGroupRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.post(`${UserGroupApi.baseURL}`, request);
  }

  static async updateUserGroup(
    id: string,
    request: UpdateGroupRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.put(`${UserGroupApi.baseURL}/${id}`, request);
  }

  static async removeGroupUsers(
    request: UpdateGroupUsersRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.post(`${UserGroupApi.baseURL}/removeUsers`, request);
  }

  static async inviteGroupUsers(
    request: UpdateGroupUsersRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.post(`${UserGroupApi.baseURL}/invite`, request);
  }

  static async updateUserGroups(
    request: UpdateUserGroupsRequest,
  ): Promise<AxiosResponse<ApiResponse<unknown>, any>> {
    return Api.put(`${UserGroupApi.baseURL}/users`, request);
  }
}

export default UserGroupApi;
