import { updateApplication } from "@appsmith/actions/applicationActions";
import { getThirdPartyAuths } from "@appsmith/selectors/tenantSelectors";
import { App as AntdApp, ModalProps } from "antd";
import { ApplicationPayload } from "ce/constants/ReduxActionConstants";
import { User } from "constants/userConstants";
import { Radio, RadioGroup, Switch } from "design-system";
import { isEmpty } from "lodash";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "selectors/settingsSelectors";
import { getCurrentUser } from "selectors/usersSelectors";
import { getAppsmithConfigs } from "@appsmith/configs";
const { enableSso } = getAppsmithConfigs();

interface SsoProps extends ModalProps {
  application: ApplicationPayload;
}
const ApplicationSso: React.FC<SsoProps> = ({ application }) => {
  const [selectRadio, SetSelectRadio] = useState<string>("");
  const [switchControl, SetSwitchControl] = useState<boolean>(false);
  const dispatch = useDispatch();
  const currentUser: User | undefined = useSelector(getCurrentUser);
  const socialLoginList = useSelector(getThirdPartyAuths);
  const isCasConnected = socialLoginList.includes("cas");
  const onChangeSSO = (val: boolean) => {
    const ssoLoginValue = selectRadio ? { [selectRadio]: val } : {};
    SetSwitchControl(val);
    if (val) {
      dispatch(
        updateApplication(application?.id, {
          ssoLoginConfig: ssoLoginValue,
        }),
      );
    } else {
      dispatch(
        updateApplication(application?.id, {
          ssoLoginConfig: ssoLoginValue,
        }),
      );
    }
  };

  const onChangeRadio = (val: string) => {
    SetSelectRadio(val);
    dispatch(
      updateApplication(application?.id, {
        ssoLoginConfig: {
          [val]: true,
        },
      }),
    );
  };

  // useEffect(() => {
  //   dispatch({
  //     type: ReduxActionTypes.FETCH_ADMIN_SETTINGS,
  //   });
  // }, []);

  useEffect(() => {
    if (!isEmpty(application.ssoLoginConfig)) {
      const fieldNames = Object.keys(application.ssoLoginConfig);
      if (application.ssoLoginConfig[fieldNames[0]]) {
        SetSwitchControl(true);
      }
      SetSelectRadio(fieldNames[0]);
    }
  }, [application.ssoLoginConfig]);

  return (
    <div>
      <div className="px-4">
        <div className="pt-3 pb-2 font-medium text-[color:var(--appsmith-color-black-800)]">
          SSO登录
        </div>
      </div>
      <div className="px-4 pb-4">
        <div className="flex justify-between items-center">
          <Switch
            data-testid={"forking-enabled-toggle"}
            isSelected={switchControl}
            isDisabled={!enableSso}
            onChange={onChangeSSO}
          >
            <div className="text-[0.7rem]">开启后可以选择SSO登录页进行登录</div>
          </Switch>
        </div>
        {switchControl && (
          <RadioGroup
            isDisabled={!enableSso}
            onChange={onChangeRadio}
            value={selectRadio}
          >
            {isCasConnected && <Radio value="CAS">CAS</Radio>}
          </RadioGroup>
        )}
      </div>
      <div
        className={`border-t-[1px] border-[color:var(--appsmith-color-black-300)]`}
      />
      {/* <div className="px-4 flex items-center justify-between">
        <div className="pt-3 pb-2 font-medium text-[color:var(--appsmith-color-black-800)]">
          Session会话
          <div className="text-[0.66rem]">
            <span className="text-[var(--appsmith-color-red-500)]">*</span>
            请在通用配置中先开启Session会话功能
          </div>
        </div>
        <div className="">
          <Switch
            data-testid={"forking-enabled-toggle"}
            onChange={onChangeSession}
          ></Switch>
        </div>
      </div>
      <div className="px-4"></div>
      <div
        className={`border-t-[1px] border-[color:var(--appsmith-color-black-300)]`}
      /> */}
    </div>
  );
};

export default ApplicationSso;
