import { Empty } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import FolderCard from "./folderCard";

const FolderCardsWrapper = styled.div`
  display: grid;
  //   grid-template-columns: repeat(4, 1fr);
  //   grid-template-rows: repeat(auto-fill, min(auto, 100%));
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-template-rows: repeat(auto-fill, min(auto, 100%));
  grid-column-gap: 30px;
  grid-row-gap: 12px;
  //   grap: 6px 12px;
  margin: 48px 26px 80px;
  @media screen and (max-width: 500px) {
    display: block;
    margin: 48px 18px 80px;
  }
`;

const FolderList: React.FC<any> = (props) => {
  return (
    <>
      {isEmpty(props.foldersData) && <Empty />}
      {!isEmpty(props.foldersData) && (
        <FolderCardsWrapper>
          {props.foldersData.map((it) => {
            return <FolderCard key={it.id} cardInfo={it} />;
          })}
        </FolderCardsWrapper>
      )}
    </>
  );
};

export default FolderList;
