import Api from "./Api";
import type { AxiosPromise } from "axios";
import type { ApiResponse } from "api/ApiResponses";

import type { InitConsolidatedApi } from "sagas/InitSagas";
import _get from "lodash/get"
class ConsolidatedPageLoadApi extends Api {
  static url = "/v1/consolidated-api";

  static async getConsolidatedPageLoadDataView(params: {
    applicationId?: string;
    defaultPageId?: string;
  }): Promise<AxiosPromise<ApiResponse<InitConsolidatedApi>>> {
    return Api.get(ConsolidatedPageLoadApi.url + "/view", params, {
      retry: {
        //需求 The request will retry for get latest SESSION when the status is 404 and the url includes /v1/pages?
        shouldRetry: (error: any) => {
          const { response, config } = error;
          return (_get(response, "status") === 401 && window.PAGEPLUG_KEYMANTECH_DEPLOY && window.PAGEPLUG_KEYMANTECH_BASE_URL);
        },
      },
    });
  }
  static async getConsolidatedPageLoadDataEdit(params: {
    applicationId?: string;
    defaultPageId?: string;
  }): Promise<AxiosPromise<ApiResponse<InitConsolidatedApi>>> {
    return Api.get(ConsolidatedPageLoadApi.url + "/edit", params,{
      //需求 The request will retry for get latest SESSION when the status is 404 and the url includes /v1/pages?
      shouldRetry: (error: any) => {
        const { response, config } = error;
        return (_get(response, "status") === 401 && window.PAGEPLUG_KEYMANTECH_DEPLOY && window.PAGEPLUG_KEYMANTECH_BASE_URL);
      },
    });
  }
}

export default ConsolidatedPageLoadApi;
