import { createReducer } from "utils/ReducerUtils";
import {
  ReduxAction,
  ReduxActionErrorTypes1,
} from "@appsmith/constants/ReduxActionConstants";
import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import type { PageDefaultMeta } from "@appsmith/api/ApplicationApi";
import { get } from "lodash";

export const initialState = {
  foldersData: [],
  modulesData: [],
  isAddFolderLoading: false,
  canCloseFolderModal: false,
  canCloseModuleModal: false,
  errorAddFolderMsg: "",
  errorAddModuleMsg: "",
};

export const handlers = {
  // 获取文件夹
  [ReduxActionTypes.INIT_MODULES_FOLDERS]: (state: any) => {
    return {
      ...state,
    };
  },

  // 成功获取文件夹
  [ReduxActionTypes.INIT_MODULES_FOLDERS_SUCCESS]: (
    state: any,
    action: any,
  ) => {
    return {
      ...state,
      foldersData: action.payload,
    };
  },

  // 获取文件夹错误
  [ReduxActionErrorTypes1.INIT_MODULES_FOLDERS_ERROR]: (state: any) => {
    return {
      ...state,
    };
  },

  // 新增文件夹
  [ReduxActionTypes.ADD_FOLDER]: (state: any, actions: any) => {
    return {
      ...state,
    };
  },

  // 新增文件夹成功
  [ReduxActionTypes.ADD_FOLDER_SUCCESS]: (state: any, actions: any) => {
    return {
      ...state,
      canCloseFolderModal: true,
    };
  },

  [ReduxActionErrorTypes1.ADD_FOLDER_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      canCloseFolderModal: false,
      errorAddFolderMsg: get(action, "payload.error.message", "").includes(
        "已存在",
      )
        ? "已存在"
        : "",
    };
  },

  // 删除文件夹
  [ReduxActionTypes.ADD_FOLDER]: (state: any, actions: any) => {
    return {
      ...state,
    };
  },

  // 更改文件夹名称
  [ReduxActionTypes.UPDATE_FOLDER_NAME]: (state: any, actions: any) => {
    return {
      ...state,
    };
  },

  // 获取模块
  [ReduxActionTypes.INIT_MODULES]: (state: any) => ({
    ...state,
  }),

  // 成功获取模块
  [ReduxActionTypes.INIT_MODULES_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      modulesData: action.payload,
    };
  },

  // 更改模块名称
  [ReduxActionTypes.MODULE_RENAME]: (state: any, actions: any) => {
    return {
      ...state,
    };
  },

  // 新增模块
  [ReduxActionTypes.ADD_MODULE]: (state: any, actions: any) => {
    return {
      ...state,
    };
  },

  // 新增模块成功
  [ReduxActionTypes.ADD_MODULE_SUCCESS]: (state: any, actions: any) => {
    return {
      ...state,
      canCloseModuleModal: true,
    };
  },

  [ReduxActionErrorTypes1.ADD_MODULE_ERROR]: (state: any, action: any) => {
    return {
      ...state,
      canCloseModuleModal: false,
      errorAddModuleMsg: get(action, "payload.error.message", "").includes(
        "已存在",
      )
        ? "已存在"
        : "",
    };
  },

  // 删除模块
  [ReduxActionTypes.DELETE_MODULE]: (
    state: any,
    action: ReduxAction<string>,
  ) => {
    return {
      ...state,
    };
  },

  // 移动模块到文件夹
  [ReduxActionTypes.MOVE_MODULE_TO_FOLDER]: (
    state: any,
    action: ReduxAction<string>,
  ) => {
    return {
      ...state,
    };
  },

  // 复制模块
  [ReduxActionTypes.COPY_MODULE]: (state: any, action: ReduxAction<string>) => {
    return {
      ...state,
    };
  },

  // 获取模块
  [ReduxActionTypes.INIT_FOLDER_MODULES]: (state: any) => ({
    ...state,
  }),

  // 导入
  [ReduxActionTypes.IMPORT_MODULE]: (state: any) => ({
    ...state,
    // importingApplication: true,
  }),

  // [ReduxActionTypes.IMPORT_APPLICATION_SUCCESS]: (
  //   state: ApplicationsReduxState,
  //   action: ReduxAction<{ importedApplication: any }>,
  // ) => {
  //   const importedApplication = action.payload;
  //   return {
  //     ...state,
  //     importingApplication: false,
  //     importedApplication,
  //   };
  // },

  [ReduxActionErrorTypes1.IMPORT_MODULE_ERROR]: (state: any) => {
    return {
      ...state,
      // importingApplication: false,
    };
  },

  // 成功获取模块
  [ReduxActionTypes.INIT_FOLDER_MODULES_SUCCESS]: (state: any, action: any) => {
    return {
      ...state,
      folderModulesData: action.payload,
    };
  },

  // 新增文件夹
  [ReduxActionTypes.ADD_FOLDER_MODULE]: (state: any, actions: any) => {
    return {
      ...state,
    };
  },

  // 删除模块
  [ReduxActionTypes.DELETE_FOLDER_MODULE]: (
    state: any,
    action: ReduxAction<string>,
  ) => {
    return {
      ...state,
    };
  },

  // 移动模块到文件夹
  [ReduxActionTypes.FOLDER_MOVE_MODULE_TO_FOLDER]: (
    state: any,
    action: ReduxAction<string>,
  ) => {
    return {
      ...state,
    };
  },

  // 复制模块
  [ReduxActionTypes.COPY_FOLDER_MODULE]: (
    state: any,
    action: ReduxAction<string>,
  ) => {
    return {
      ...state,
    };
  },
};

const modulesReducer = createReducer(initialState, handlers);

export type creatingApplicationMap = Record<string, boolean>;

export interface DeletingMultipleApps {
  list?: string[];
  isDeleting?: boolean;
}

export interface Application {
  id: string;
  name: string;
  workspaceId: string;
  isPublic: boolean;
  appIsExample: boolean;
  new: boolean;
  defaultPageId: string;
  pages: PageDefaultMeta[];
  userPermissions: string[];
}

export default modulesReducer;
