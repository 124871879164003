import Api from "api/Api";
import { ApiResponse } from "api/ApiResponses";
import { AxiosPromise } from "axios";
import { ImportModuleRequest } from "ce/actions/modulesActions";
import { FetchReleaseItemsResponse } from "../ApplicationApi";

interface AddModuleRequest {
  name: string;
  folderId?: string;
}

export class ModulesApi extends Api {
  static baseURL = "v1/applications";
  static baseModuleURL = "v1/module";
  static baseFolderURL = "v1/folder";
  static usersURL = "v1/users";

  static async createUser(request: any): Promise<AxiosPromise<any>> {
    return Api.post(ModulesApi.usersURL, request);
  }

  static async getReleaseItems(): Promise<
    AxiosPromise<FetchReleaseItemsResponse>
  > {
    return Api.get(ModulesApi.baseURL + "/releaseItems");
  }

  static async getFolders(search: string): Promise<AxiosPromise<any>> {
    return Api.get(ModulesApi.baseFolderURL + `?search=${search}`);
  }

  static async addFolders(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.post(ModulesApi.baseFolderURL, {
      name: request.name,
    });
  }

  static async updateFolderName(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.put(ModulesApi.baseFolderURL, {
      name: request.name,
      id: request.id,
    });
  }

  static async deleteFolder(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.delete(ModulesApi.baseFolderURL + "/" + request.id);
  }

  static async getModules(search: string): Promise<AxiosPromise<any>> {
    return Api.get(ModulesApi.baseModuleURL + `?search=${search}`);
  }

  static async addModule(
    request: AddModuleRequest,
  ): Promise<AxiosPromise<any>> {
    return Api.post(ModulesApi.baseModuleURL, {
      name: request.name,
      folderId: request.folderId,
    });
  }

  static async updateModuleName(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.put(ModulesApi.baseModuleURL, {
      name: request.name,
      id: request.id,
    });
  }

  static async moveModule(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.put(ModulesApi.baseModuleURL + "/move", {
      name: request.name,
      id: request.id,
      folderId: request.folderId,
      folderName: request.folderName,
    });
  }

  static async deleteModule(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.delete(ModulesApi.baseModuleURL + "/" + request.id);
  }

  static async copyModule(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.post(ModulesApi.baseModuleURL + "/copy", {
      name: request.name,
      id: request.id,
    });
  }

  // 文件夹内
  static async getFolderModules(
    search: string,
    folderId: string,
  ): Promise<AxiosPromise<any>> {
    return Api.get(
      ModulesApi.baseFolderURL + `/${folderId}/modules` + `?search=${search}`,
    );
  }

  static async addFolderModule(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.post(ModulesApi.baseModuleURL, {
      name: request.name,
    });
  }

  static async moveFolderModule(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.put(ModulesApi.baseModuleURL + "/move", {
      name: request.name,
      id: request.id,
      folderId: request.folderId,
      folderName: request.folderName,
    });
  }

  static async deleteFolderModule(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.delete(ModulesApi.baseModuleURL + "/" + request.id);
  }

  static async copyFolderModule(
    request: Record<string, string>,
  ): Promise<AxiosPromise<any>> {
    return Api.post(ModulesApi.baseModuleURL + "/copy", {
      name: request.name,
      id: request.id,
    });
  }

  static async importModule(
    request: ImportModuleRequest,
  ): Promise<AxiosPromise<ApiResponse>> {
    const formData = new FormData();
    if (request.moduleFile) {
      formData.append("file", request.moduleFile);
    }
    return Api.post(
      `${ModulesApi.baseModuleURL}/import?folderId=${request.folderId}`,
      formData,
      null,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }
}
