import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { NavigationSetting } from "constants/AppConstants";
import { StyledApplicationLogo } from "./ApplicationName.styled";
import { StyledImage } from "../Sidebar.styled";
import { isEllipsisActive } from "utils/helpers";
import { Tooltip } from "design-system";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";
import { getCurrentApplication } from "selectors/applicationSelectors";
import _get from "lodash/get";

interface ApplicationNameProps {
  appName?: string;
  navColorStyle: NavigationSetting["colorStyle"];
  navStyle: NavigationSetting["navStyle"];
  navOrientation: NavigationSetting["orientation"];
  primaryColor: string;
  forSidebar?: boolean;
  showAbbr: boolean;
}

const ApplicationLogo = (props: ApplicationNameProps) => {
  const {
    appName,
    forSidebar,
    navColorStyle,
    navStyle,
    navOrientation,
    primaryColor,
    showAbbr,
  } = props;
  const applicationNameRef = useRef<HTMLDivElement>(null);
  const [ellipsisActive, setEllipsisActive] = useState(false);
  const [logoInfo, setLogoInfo] = useState<any>({});
  const isMobile = useIsMobileDevice();
  const currentLayout = useSelector(getCurrentApplication)?.viewerLayout;

  useEffect(() => {
    if (isEllipsisActive(applicationNameRef?.current)) {
      setEllipsisActive(true);
    }
  }, [applicationNameRef, appName]);

  useEffect(() => {
    if (currentLayout) {
      try {
        const current = JSON.parse(currentLayout);
        setLogoInfo(_get(current, "logoInfo", {}));
      } catch (e) {
        console.log(e);
      }
    }
  }, [currentLayout]);

  const showLogo = showAbbr ? !!logoInfo?.small : !!logoInfo?.default;
  if (!showLogo) {
    return null;
  }
  return (
    <Tooltip
      content={appName || ""}
      isDisabled={!ellipsisActive}
      placement="bottom"
    >
      <StyledApplicationLogo
        className="overflow-hidden text-base overflow-ellipsis whitespace-nowrap t--app-viewer-application-name"
        forSidebar={forSidebar}
        isMobile={isMobile}
        navColorStyle={navColorStyle}
        navOrientation={navOrientation}
        navStyle={navStyle}
        primaryColor={primaryColor}
        ref={applicationNameRef}
      >
        <StyledImage
          alt="logo"
          src={showAbbr ? logoInfo?.small : logoInfo?.default}
        />
      </StyledApplicationLogo>
    </Tooltip>
  );
};

export default ApplicationLogo;
