import React from "react";
import type { User } from "constants/userConstants";
import { ANONYMOUS_USERNAME } from "constants/userConstants";
import ProfileDropdown from "pages/common/ProfileDropdown";
import type { NavigationSetting } from "constants/AppConstants";
import classNames from "classnames";
import {
  StyledContainer,
  StyledText,
  StyledTextContainer,
} from "./SidebarProfileComponent.styled";

interface SidebarProfileComponent {
  currentUser: User | undefined;
  primaryColor: string;
  navColorStyle: NavigationSetting["colorStyle"];
  isMinimal: boolean;
  isOpen: boolean;
  isInline: boolean;
}

const SidebarProfileComponent = (props: SidebarProfileComponent) => {
  const {
    currentUser,
    isInline,
    isMinimal,
    isOpen,
    navColorStyle,
    primaryColor,
  } = props;
  const hideAvatorDropdown = window.PAGEPLUG_KEYMANTECH_DEPLOY;
  return currentUser && currentUser.username !== ANONYMOUS_USERNAME ? (
    <StyledContainer
      className={classNames({ "justify-center": isMinimal })}
      isInline={isInline}
      isOpen={isOpen}
      navColorStyle={navColorStyle}
      primaryColor={primaryColor}
    >
      <ProfileDropdown
        modifiers={{
          offset: {
            enabled: true,
            offset: `0, 0`,
          },
        }}
        name={currentUser.name}
        navColorStyle={navColorStyle}
        noAction={hideAvatorDropdown}
        photoId={currentUser?.photoId}
        primaryColor={primaryColor}
        userName={currentUser?.username || ""}
      />

      {!isMinimal && (
        <StyledTextContainer>
          <StyledText navColorStyle={navColorStyle} primaryColor={primaryColor}>
            {isOpen ? currentUser.name : ""}
          </StyledText>
          <StyledText
            isEmail
            navColorStyle={navColorStyle}
            primaryColor={primaryColor}
          >
            {isOpen ? currentUser.email : ""}
          </StyledText>
        </StyledTextContainer>
      )}
    </StyledContainer>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

export default SidebarProfileComponent;
