import { ReduxActionTypes } from "@appsmith/constants/ReduxActionConstants";
import type { Operation } from "constants/AppConstants";

export const addOperation = (payload: Operation[]) => ({
  type: ReduxActionTypes.OPERATION_LOG,
  payload,
});

export const clearOperations = () => ({
  type: ReduxActionTypes.CLEAR_OPERATION_LOGS,
  payload: {},
});
